body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header h1 {
  font-size: 4.5em;
  font-weight: 900;
  text-align: center;
  padding: 0.5rem 0;
}

.card {
  margin: 1rem;
  max-height: auto;
}

.card img {
  min-width: 50%;
  max-width: 70%;
  max-height: 70%;
  align-items: center;
}
.ground {
  text-align: center;
  font-size: 300px;
}
.about-h2 {
  text-align: left;
  margin: 15px;
}
.about-h3 {
  text-align: left;
  margin: 15px;
}
nav {
  position: center;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
}
.link {
  display: inline-table;
  position: center;
  margin: 1rem 2rem;
  text-decoration: none;
  font-size: 1.2em;
  color: black;
  border-bottom: 2px solid black;
  transition: 0.1s;
}
nav a {
  display: inline-table;
  position: center;
  margin: 1rem 2rem;
  text-decoration: none;
  font-size: 1.2em;
  color: black;
  border-bottom: 2px solid black;
  transition: 0.1s;
}
.red {
  margin: 15px;
  font-size: 40px;
}
.link:hover {
  color: rgb(255, 71, 221);
  border-bottom: 2px solid rgb(255, 71, 221);
}
.link:active {
  color: rgb(255, 71, 221);
  border-bottom: 2px solid rgb(255, 71, 221);
}
.new-synth-form {
  padding: 2rem 1rem;
  margin: 2rem 0;
  background-color: whitesmoke;
}

.new-synth-form form {
  display: flex;
  justify-content: center;
}

.new-synth-form input {
  margin-right: 2rem;
  flex: 1;
}

.new-synth-form button {
  width: 150px;
}
